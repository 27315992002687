import superAdminMenu from "./SuperAdmin";
import subAdminMenu from "./SubAdmin";
import brandAdminMenu from "./BrandAdmin";
// ==============================|| MENU ITEMS ||============================== //

const menuItem = {
  items: [superAdminMenu, subAdminMenu, brandAdminMenu]
};

export default menuItem;
