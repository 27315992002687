// Brand

export const GET_ALL_BRANDS = "get_all_brands";
export const GET_ALL_BRANDS_BY_ADMIN = "get_all_brands_by_admin";
export const GET_ALL_BRANDS_SUCCESS = "get_all_brands_success";
export const GET_ALL_BRANDS_BY_ADMIN_SUCCESS = "get_all_brands_by_admin_success";
export const ADD_BRAND = "add_brand";
export const UPDATE_BRAND = "update_brand";
export const UPDATE_PROPERTY = "update_property";
export const DELETE_BRAND = "delete_brand";
