// Brand

export const GET_ALL_CURRENCIES = "GET_ALL_CURRENCIES";

export const GET_ALL_CURRENCIES_SUCCESS = "GET_ALL_CURRENCIES_SUCCESS";
export const GET_ALL_CHAIN = "GET_ALL_CHAIN";

export const GET_ALL_CHAIN_SUCCESS = "GET_ALL_CHAIN_SUCCESS";
export const ADD_CURRENCY = "ADD_CURRENCY";
export const UPDATE_CURRENCY = "UPDATE_CURRENCY";
export const DELETE_CURRENCY = "DELETE_CURRENCY";
