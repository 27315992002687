export const GET_ALL_GALILEO = "GET_ALL_GALILEO";
export const GET_ALL_GALILEO_SUCCESS = "GET_ALL_GALILEO_SUCCESS";
export const GET_ALL_TRENDING = "GET_ALL_TRENDING";
export const GET_ALL_TRENDING_SUCCESS = "GET_ALL_TRENDING_SUCCESS";
export const GET_ALL_BRANDS = "GET_ALL_BRANDS";
export const GET_ALL_BRANDS_SUCCESS = "GET_ALL_BRANDS_SUCCESS";
export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const GET_ALL_CATEGORIES_SUCCESS = "GET_ALL_CATEGORIES_SUCCESS";
export const GET_NFT_DATA = "GET_NFT_DATA";
export const GET_NFT_DATA_SUCCESS = "GET_NFT_DATA_SUCCESS";
export const BMW = "BMW";
export const BMW_SUCCESS = "BMW_SUCCESS";
export const COMINGSOON_MARKETPLACE = "comingsoon_market_place";
export const COMINGSOON_MARKETPLACE_SUCCESS = "comingsoon_market_place_success";

export const GET_NFT_TAX = "get_nft_tax";
export const GET_NFT_TAX_SUCCESS = "get_nft_tax_success";
