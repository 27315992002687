export const GET_ALL_NFT = "get_all_nft";
export const GET_ALL_NFT_SUCCESS = "get_all_nft_success";
export const GET_ALL_BULKNFT = "GET_ALL_BULKNFT";
export const GET_ALL_BULKNFT_SUCCESS = "GET_ALL_BULKNFT_SUCCESS";
export const GET_ALL_NFT_SUPER_ADMIN = "get_all_nft_super_admin";
export const GET_ALL_NFT_USER = "get_all_nft_user";
export const GET_ALL_NFT_SUPER_ADMIN_SUCCESS = "get_all_nft_super_admin_success";
export const GET_ALL_NFT_USER_SUCCESS = "get_all_nft_user_success";
export const GET_ALL_NFT_RESOLD_SUCCESS = "get_all_nft_resold_success";
export const GET_ALL_NFT_RESOLD = "get_all_nft_resold";
export const ADD_NFT = "add_nft";
export const EDIT_NFT = "edit_nft";
export const EDIT_REQUEST_NFT = "EDIT_REQUEST_NFT";
export const GET_EDITED_NFT_DATA = "get_edited_nft_data";
export const UPDATE_NFT_DYNAMIC_METADATA = "update_nft_dynamic_metadata";
export const REQUEST_NFT_FOR_MINTING = "request_nft_for_minting";
export const MINT_NFT = "mint_nft";
export const MINT_LOADER_NFT = "mint_loader_nft";
export const RESOLD_NFT_LOADER = "resold_nft_loader";
export const RESOLD_NFT_LOADER_SUCCESS = "resold_nft_loader_SUCCESS";
export const LAZY_MINT_NFT = "lazy_mint_nft";
export const DELETE_NFT = "delete_nft";
export const REMOVED_NFT = "REMOVED_NFT";
export const REJECT_NFT = "reject_nft";
export const REJECT_EDIT_NFT = "REJECT_EDIT_NFT";
export const APPROVE_EDIT_NFT = "APPROVE_EDIT_NFT";
export const BUY_NFT = "buy_nft";
export const BULK_BUY_NFT = "BULK_BUY_NFT";
export const RESELL_NFT = "resell_nft";
export const RESOLED_NFT = "RESOLED_NFT";
export const CANCLE_RESELL_NFT = "cancle_resell_nft";
export const CANCLE_RESELL_NFT_SUCCESS = "cancle_resell_nft_success";
export const REDEEM_NFT = "redeem_nft";
export const GET_NFT_BUYER = "nft_buyer";
export const GET_NFT_BUYER_SUCCESS = "nft_buyer_success";
export const ADD_DELIVERY_NFT = "add_delivery_nft";
export const CHANGE_TOKEN_ID = "change_token_id";
export const REQUEST_CHANGE_NFT = "request_change_nft";
export const EDIT_REQUEST_METADATA_NFT = "edit_request_metadata_nft";
export const GET_EDIT_NFT_REQUEST = "get_edit_nft_request";
export const META_DATA_NFT_TOKEN_URI = "meta_data_nft_tokenuri";
export const GET_NFT_TOKEN_SUCCESS = "get_nft_token_success";
export const META_DATA_URI_TOKEN_CANCEL = "meta_data_uri_token_cancel";
export const ADD_PRIMARY_IMAGE_NFT = "add_primary_image_nft";
export const BUY_NFT_CART = "buy_nft_cart";
export const IS_ALREADY_SOLD = "is_already_sold";
export const SET_STATE_MINTED_NFT_SUCCESS = "set_state_minted_nft_success";
export const UPLOAD_NFT_IMAGES = "upload_nft_images";
export const UPLOAD_NFT_IMAGES_SUCCESS = "upload_Nft_images_success";
export const UPLOAD_IMAGE_BACKGROUND_CHANGE = "upload_image_background_Change";
export const UPLOAD_IMAGE_BACKGROUND_CHANGE_SUCCESS = "upload_image_background_change_success";
