export const GET_ALL_BRAND_CATEGORIES = "get_all_brand_categories";
export const GET_ALL_BRAND_CATEGORIES_BY_ADMIN = "get_all_brand_categories_by_admin";

export const GET_ALL_BRAND_CATEGORIES_SUCCESS = "get_all_brand_categories_success";
export const GET_ALL_BRAND_CATEGORIES_BY_ADMIN_SUCCESS = "get_all_brand_categories_by_admin_success";
export const GET_ALL_BRAND_CATEGORIES_ADMIN = "get_all_brand_categories_admin";
export const GET_ALL_BRAND_CATEGORIES_ADMIN_SUCCESS = "get_all_brand_categories_admin_success";
export const GET_ALL_CATEGORIES_DROPDOWN = "get_all_categories_dropdown";
export const GET_ALL_CATEGORIES_DROPDOWN_SUCCESS = "get_all_categories_dropdown_success";
export const ADD_BRAND_CATEGORY = "add_brand_category";
export const UPDATE_BRAND_CATEGORY = "update_brand_category";
export const DELETE_BRAND_CATEGORY = "delete_brand_category";
export const DEACTIVATION_BRAND = "DEACTIVATION_BRAND";
export const UPDATE_BRAND_CATEGORY_TOGGLE = "update_brand_category_toggle";
