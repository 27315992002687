export const LOGIN = "login";
export const LOGIN_SUCCESS = "login_success";
export const DELETE_WALLET = "DELETE_WALLET";
export const DASHBOARD = "dashboard";
export const DASHBOARD_SUCCESS = "dashboard_success";
export const VERIFY = "VERIFY";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";
export const BRAND_DASHBOARD = "brand_dashboard";
export const BRAND_DASHBOARD_SUCCESS = "brand_dashboard_success";
export const SIGN_UP = "sign_up";
export const SIGN_UP_SUCCESS = "sign_up_success";
export const UPDATE_EMAIL = "UPDATE_EMAIL";
export const UPDATE_EMAIL_SUCCESS = "update_email_address";
export const IS_KYC_POPUP_SHOWN_TO_USER = "IS_KYC_POPUP_SHOWN_TO_USER";
export const IS_KYC_POPUP_SHOWN_TO_USER_SUCCESS = "IS_KYC_POPUP_SHOWN_TO_USER_SUCCESS";
export const EMAIL_RESEND = "EMAIL_RESEND";
export const SIGN_UP_SOCIAL = "sign_up_social";
export const SIGN_UP_SOCIAL_SUCCESS = "sign_up_social_success";
export const SET_LOADER = "SET_LOADER";
export const LOGOUT = "logout";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const SET_WALLET_ADDRESS = "set_wallet_address";
export const EDIT_PROFILE_REQUEST = "EDIT_PROFILE_REQUEST";
export const EDIT_PROFILE_SUCCESS = "EDIT_PROFILE_SUCCESS";
export const SUPPORT_REQUEST = "SUPPORT_REQUEST";
export const SUPPORT_REQUEST_SUCCESS = "SUPPORT_REQUEST_SUCCESS";
export const RESTRICT_APPLICATION = "restrict_application";
export const RESTRICT_APPLICATION_SUCCESS = "restrict_application_success";
export const KYC_NOTIFICATION_SUCCESS = "KYC_NOTIFICATION_SUCCESS";
export const GET_DROPDOWN_VALUE = "GET_DROPDOWN_VALUE";
export const SOCKET_CONNECTION = "socket_connection";
export const SOCKET_CONNECTION_SUCCESS = "socket_connection_success";
