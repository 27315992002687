// action - account reducer
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const REGISTER = "REGISTER";
export const FIREBASE_STATE_CHANGED = "FIREBASE_STATE_CHANGED";

// action - customization reducer
export const SET_MENU = "@customization/SET_MENU";
export const MENU_TOGGLE = "@customization/MENU_TOGGLE";
export const MENU_OPEN = "@customization/MENU_OPEN";
export const MENU_TYPE = "@customization/MENU_TYPE";
export const PRESET_COLORS = "@customization/PRESET_COLORS";
export const THEME_LOCALE = "@customization/THEME_LOCALE";
export const THEME_RTL = "@customization/THEME_RTL";
export const SET_FONT_FAMILY = "@customization/SET_FONT_FAMILY";
export const SET_BORDER_RADIUS = "@customization/SET_BORDER_RADIUS";
export const SET_OUTLINED_FILLED = "@customization/SET_OUTLINED_FILLED";

// action - snackbar
export const SNACKBAR_OPEN = "@snackbar/SNACKBAR_OPEN";

// action - cart
export const ADD_PRODUCTS = "@cart/ADD_PRODUCTS";
export const REMOVE_PRODUCT = "@cart/REMOVE_PRODUCT";
export const UPDATE_QUANTITY = "@cart/UPDATE_QUANTITY";
export const NEXT_STEP = "@cart/NEXT_STEP";
export const BACK_STEP = "@cart/BACK_STEP";
export const SET_STEP = "@cart/SET_STEP";
export const SET_BILLING_ADDRESS = "@cart/SET_BILLING_ADDRESS";
export const SET_DISCOUNT = "@cart/SET_DISCOUNT";
export const SET_SHIPPING_CHARGE = "@cart/SET_SHIPPING_CHARGE";
export const SET_PAYMENT_METHOD = "@cart/SET_PAYMENT_METHOD";
export const SET_PAYMENT_CARD = "@cart/SET_PAYMENT_CARD";
export const RESET_CART = "@cart/RESET_CART";
